@charset "UTF-8";
@import '../../colors.css';

@media screen and (min-width: 768px) {
    .projetos article {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        padding: 20px;
    }

    .projetos .texto {
        width: 80%;
    }

    .projetos img {
        width: 90%;
        height: auto;
    }
}

@media screen and (min-width: 992px) {
    .titulo {
        height: 220px;
        background-attachment: fixed;
        background-position: bottom;
    }

    .projetos article {
        width: 65%;
    }
}