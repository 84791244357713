@charset "UTF-8";
@import '../../colors.css';

main#news-management button#create-new{
    margin: 50px;
    background-color: green;
    border: none;
}

main#news-management article#news{
    width: 70%;
    background-color: var(--color1);
    border-radius: 15px;
    text-align: left;
    margin: 50px;
    margin: 50px auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

main#news-management article#news section#infos{
    padding: 30px;
    padding-bottom: 0;
}

main#news-management article#news section#infos p{
    padding: 5px;
    word-wrap: break-word;
    white-space: normal;
}

main#news-management article#news img{
    margin-top: 10px;
    width: 200px;
    height: 200px;
}

main#news-management article#news section#edit-and-delete{
    display: block;
}

main#news-management article#news button#edit, button#delete{
    border: none;
    color: black;
    margin: 10px 25px;
    cursor: pointer;
}

main#news-management article#news button#delete{
    background-color: red;
}

main#news-management article#news button#edit{
    background-color: yellow;
}

main#news-management form#edit {
    display: block;
    margin:  0 auto;
    width: 90%;
}

main#news-management form#edit .texts {
    border: 1px solid var(--color5);
    padding: 5px;
}