@charset "UTF-8";
@import '../../colors.css';

.titulo {
    background-image: url('./assets/ceu.jpg');
    background-size: 100%;
    height: 170px;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titulo h2 {
    color: white;
    text-shadow: 1px 1px 0 black;
    text-align: center;
    width: 90%;
    font-weight: 350;
}

.projetos {
    margin-top: 50px;
}

.projetos article {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    width: 95%;
    margin: 30px auto;
    padding-bottom: 15px;
}

.projetos article .texto {
    display: block;
    padding: 25px;
}

.projetos article .texto h3 {
    margin-bottom: 15px;
}

.projetos article img {
    display: block;
    width: 50%;
    height: auto;
    margin: 0px auto;
    border-radius: 10%;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.523);
}

.projetos article img:hover {
    transform: scale(1.1);
    transition: 0.5s;
}
