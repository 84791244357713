@charset "UTF-8";
@import '../../colors.css';

article#title {
    background-color: var(--color5);
    color: white;
    text-align: center;
    padding: 15px;
}

article#title h1 {
margin-bottom: 20px;
}

main#candidates article#filters {
    display: block;
    text-align: center;
    padding: 30px;
}

main#candidates article#filters select{
    border: 1px solid var(--color5);
}

main#candidates button#excel{
    background-color: green;
    margin: 10px;
    border: none;
}

main#candidates button#excel:hover{
    color: white;
}

main#candidates article#table {
    max-height: 700px;
    overflow-y: auto; 
}

main#candidates table {
    max-width: 90%;
    border-collapse: collapse;
    text-align: left;
    margin: 20px auto;
    margin-bottom: 40px;
    font-size: 0.8em;
}

main#candidates th, td {
    padding: 12px 15px; 
    border: 1px solid #ddd; 
}


main#candidates th {
    background-color: #f4f4f4;
    font-weight: bold;
}

main#candidates tr:nth-child(even) {
    background-color: #f9f9f9; 
}

main#candidates tr:hover {
    background-color: #e9e9e9; 
}

main#candidates a {
    color: var(--color3); 
    text-decoration: none;
}

main#candidates a:hover {
    text-decoration: underline; 
}
