@charset "UTF-8";
@import '../../colors.css';

main#rp {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

main#rp article#title {
    background-color: var(--color5);
    color: white;
    width: 100%;
    line-height: 5em;
    text-align: center;
    margin-bottom: 30px;
}

main#rp button#create {
    background-color: green;
    border: none;
    font-size: 1.1em;
    box-shadow: 2px 2px 10px rgba(0, 128, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

main#rp button#create:hover {
    transform: scale(1.05);
    box-shadow: 4px 4px 15px rgba(0, 128, 0, 0.4);
    color: white;
}

main#rp article.process {
    background-color: var(--color1);
    width: 75%;
    padding: 20px;
    margin: 20px;
    border-radius: 15px;
}

main#rp article.process section#edit-and-delete button {
    color: black;
    border: none;
    font-size: 1.1em;
    margin-bottom: 0;
    margin-right: 25px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

main#rp article.process section#edit-and-delete {
    margin-top: 15px;
}


main#rp article.process section#edit-and-delete button#edit {
    background-color: yellow;
    box-shadow: 2px 2px 10px rgba(255, 255, 0, 0.1);
}


main#rp article.process section#edit-and-delete button#delete {
    background-color: red;
    box-shadow: 2px 2px 10px rgba(255, 0, 0, 0.1);
}

main#rp article.process section#edit-and-delete button#edit:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 10px rgba(255, 255, 0, 0.9);
}


main#rp article.process section#edit-and-delete button#delete:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 10px rgba(255, 0, 0, 0.9);
}