@charset "UTF-8";
@import '../../colors.css';


@media screen and (min-width: 768px) {
    .footer-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1px;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .footer-content img.logo,
    .footer-content section.contato,
    .footer-content section.localizacao {
        flex: 0 1 300px;
        max-width: 300px; 
        text-align: center; 
        margin: 0;
    }

    section.copy {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }
}
