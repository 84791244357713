@charset "UTF-8";
@import '../../colors.css';

@media screen and (min-width: 768px) {

    menu#options {
        display: block;
    }

    i#burguer {
        display: none;
    }

    ul {
        text-align: center;
    }

    ul li {
        display: inline-block;
        padding: 5px;
    }

    ul li a {
        border-top: none;
        font-size: .8em;
    }

    ul li a:hover {
        color: var(--color4);
    }
}



@media screen and (min-width: 992px) {
    nav {
        display: flex; 
        align-items: center; 
        justify-content: space-between; 
        padding: 20px; 
    }

    ul li a {
        font-size: 1em;
    }

    img.logo {
        max-width: 200px;
        height: auto;
        margin-left: 200px;
    }

    menu#options {
        flex-grow: 1; /* CENTRALIZA O MENU */
    }
}