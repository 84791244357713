@charset "UTF-8";
@import '../../colors.css';

main#login article#login-title {
    background-color: var(--color5);
    color: white;
    padding: 15px;
    text-align: center;
}

main#login article#login-form {
    margin: 30px 0;
    display: block;
    text-align: center;
}

main#login form {
    margin: 30px;
    padding: 15px;
    text-align: left;
    width: 90%;
    background-color: rgba(128, 128, 128, 0.142);
}

main#login form input {
    display: block;
    border: 1px solid black;
    margin: 40px auto;
}

main#login form button {
    display: block;
    margin: 10px auto;
    width: 250px;
}

@media screen and (min-width: 768px) {
    main#login form {
        width: 50%;
    }
}

@media screen and (min-width: 992px) {
    main#login form {
        width: 25%;
    }
}