@charset "UTF-8";
@import '../../colors.css';

main#contact-management {
    width: 100%;
    margin: 0 auto;
}

main#contact-management h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

main#contact-management article#title {
    background-color: var(--color5);
}

main#contact-management input#search {
    margin: 40px;
}

main#contact-management input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--color4);
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

main#contact-management article#table {
    max-width: 98%;
    overflow-y: auto;
    justify-items: center;
    margin: 25px auto;
}

main#contact-management table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

main#contact-management thead {
    background: #007bff;
    color: white;
    font-weight: bold;
}

main#contact-management th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}

main#contact-management td {
    word-break: break-word;
    white-space: normal;
}

main#contact-management th {
    text-transform: uppercase;
}

main#contact-management tbody tr:hover {
    background: #f1f1f1;
}

main#contact-management p {
    text-align: center;
    font-size: 16px;
    color: #666;
    margin-top: 20px;
}
