/* src/App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat','Open Sans', sans-serif; 
  line-height: 1.6;
  font-size: 0.9em;
}
