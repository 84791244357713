@charset "UTF-8";
@import '../../colors.css';



main#video video {
    width: 100%; /* Ocupa toda a largura */
    height: auto; /* Mantém a proporção */
}



.carousel {
    width: 100%;;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.slides {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    min-width: 100%;
    height: 200px;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2222ffc2;
    border: none;
    color: white;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
}

.carousel-button.prev {
    left: 1px;
}

.carousel-button.next {
    right: 1px;
}