@charset "UTF-8";
@import '../../colors.css';

footer {
    background-color: var(--color1);
    color: var(--color5);
    font-weight: 500;
    font-size: small;
    max-width: 100%;
}

.mensagem {
    background-color: rgb(75, 75, 75);
    text-align: center;
    width: 100%;
    color: white;
}
.mensagem p {
    padding: 20px;
}

a {
    text-decoration: none;
    color: var(--color5)
}

a:hover {
    color: var(--color4);
    text-decoration: solid;
}

section.contato,
section.localizacao {
    padding: 15px;
}

img.logo {
    display: block;
    margin: 0 auto;
    max-width: 150px;
    height: auto;
}

section.copy {
    border-top: solid 1px var(--color3);
    text-align: center;
    font-size: small;
    font-weight: 400;
    padding: 10px;
    margin-top: 20px;
}

section#restricted-area button {
    background-color: rgba(128, 128, 128, 0);
    border: none;
    color: black;
    cursor: pointer;
    font-size: x-small;
}
