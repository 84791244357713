@charset "UTF-8";
@import '../../colors.css';


form {
    border: solid 2px rgba(0, 0, 0, 0.2);
    display: inline-block;
    padding: 20px;
    border-radius: 20px;
    margin: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}


input,
select {
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 20px;
    width: 350px;
    height: 50px;
    text-align: center;
    font-size: 1em;
}

input[type="file"] {
    border: none;
    border-radius: 0%;
    margin-bottom: 0;
    cursor: pointer;
}

input[type="submit"] {
    background-color: var(--color5);
    color: white;
    cursor: pointer;
}

input[type="submit"]:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

label span {
    color: red;
    font-size: 0.7em;
}
