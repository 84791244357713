@charset "UTF-8";
@import '../../../colors.css';

main#buttons {
    text-align: center;
    padding: 30px;
    background-color: rgba(115, 115, 115, 0.218);
}

main#buttons a {
    display: block;
    width: 50%;
    margin: 0 auto;
    cursor: pointer;
}

main#buttons button {
    width: 100%;
    height: 60px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.33);
}

main#buttons button:hover {
    cursor: pointer;
}