@charset "UTF-8";
@import '../../colors.css';

div#complete-new {
    width: 95%;
    text-align: center;
    margin: 10px auto;
    margin-bottom: 30px;
    overflow-x: hidden;
    max-width: 100%;
}

div#complete-new article#postdate {
    text-align: left;
    margin-top: 20px;
}

div#complete-new h1#complete-new-title {
    margin: 30px;
}

div#complete-new img#complete-new-img {
    width: 400px;
    height: auto;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.464);
}

div#complete-new pre#complete-new-content {
    margin: 30px 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: left;
    width: 98%;
    max-width: 100%;
    box-sizing: border-box;
}

div#complete-new a#pdf-link{
    color: var(--color4);
    font-size: large;
}

@media screen and (min-width: 768px) {    
    div#complete-new img#complete-new-img {
        width: 550px;
        height: auto;
        margin: 20px;
    }
    
    div#complete-new pre#complete-new-content {
        font-size: larger;
    }
}