@charset "UTF-8";
@import '../../colors.css';

@media screen and (min-width: 768px) {
    .slide {
        min-width: 100%;
        height: 400px;
    }
}

@media screen and (min-width: 992px) {
    .slide {
        min-width: 100%;
        height: 750px;
    }
}

