@charset "UTF-8";
@import '../../colors.css';


@media screen and (min-width: 768px) {
    main#transparency > section#resume, main#transparency > section.cities {
    width: 80%;
    }
    main#transparency > section.cities {
        margin-top: 50px;
        text-align: center;
    }
}
@media screen and (min-width: 992px) {
    main#transparency > section#resume, main#transparency > section.cities {
    width: 65%;
    }
}