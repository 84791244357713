@charset "UTF-8";
@import '../../colors.css';

main.projects {
    width: 100%;
    border-bottom: 1px solid rgba(128, 128, 128, 0.291);
    margin: 40px 0;
}

main.projects img.projects-img{
    width: 250px;
    height: auto;
    display: block;
    margin: 0 auto;
}

main.projects h3.projects-title{
    text-align: center;
    margin-top: 20px;
    color: var(--color4);
}

main.projects p.projects-city{
    text-align: center;
    margin-top: 5px;
}

@media screen and (min-width: 768px) {
    main.projects img.projects-img{
        width: 400px;
    }
}