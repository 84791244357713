@charset "UTF-8";
@import '../../colors.css';

main#transparency {
    background-color: var(--color5);
    color: white;
}

main#transparency > section#resume {
    width: 90%;
    margin: 0 auto;
    padding: 5px;
}

main#transparency > section#resume > h1 {
    text-align: center;
    padding: 25px;
    padding-bottom: 40px;
}

main#transparency > section.cities {
    width: 90%;
    margin: 0 auto;
    display: block;
}

h3.city {
    padding: 30px 0;
    color: white;
    transition: color 0.4s ease, transform 0.4s ease;
}

h3.city:hover {
    color: var(--color4);
    transform: scale(1.1);
}
