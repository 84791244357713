@charset "UTF-8";
@import '../../colors.css';

div#news-preview {
text-align: center;
width: 95%;
margin: 0 auto;
border-bottom: 1px solid var(--color3);
margin-bottom: 50px;
}

div#news-preview article#postdate {
text-align: left;
margin-bottom: 15px;
}

img#news-img {
    width: 300px;
    height: auto;
    margin: 15px;
    border-radius: 15px;
}

p#news-resume {
    margin-bottom: 50px;
}