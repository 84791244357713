@charset "UTF-8";
@import '../../colors.css';

nav {
    background-color: white;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

img.logo {
    display: block;
    margin: 0 auto;
    max-width: 150px;
    height: auto;
}

menu#options {
    display: none;
}

i#burguer {
    color: var(--color4);
    display: block;
    text-align: center;
    padding: 5px;
    margin-top: 3px;
    cursor: pointer;
}

i#burguer:hover {
    background-color: var(--color5);
}

ul {
    list-style: none;
}

ul li a {
    display: block;
    padding: 15px;
    text-decoration: none;
    text-align: center;
    font-size: .8em;
    color: var(--color4);
    font-weight: 800;
    border-top: 1.5px solid var(--color4);
}

ul li a:hover {
    color: var(--color5) !important;
    border-bottom: 2px solid var(--color5);
    transform: scale(1.1);
    transition: 0.3s;
}

.active {
    color: var(--color5);
    border-bottom: 2px solid var(--color5);
}


