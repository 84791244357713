@charset "UTF-8";
@import '../../colors.css';

.quem-somos {
    background-color: var(--color3);
    text-align: center;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.138);
    color: var(--color5);
}

.quem-somos h2 {
    padding: 15px
}

.quem-somos p {
    padding: 15px;
}

.missao-visao-valores .titulo {
    background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(../../assets/logo.png);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    margin: 50px auto;
}

.missao-visao-valores .titulo h2 {
    color: var(--color5);
    text-align: center;
    font-weight: 600;
    text-shadow: none;
}

.caixas {
    width: 70%;
    margin: 0 auto;
}

.caixas img {
    width: 22%;
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
}

.caixas h3 {
    margin-bottom: 20px;
    text-align: center;
}

.caixas strong {
    margin-right: 5px;
}

.caixas p {
    margin-bottom: 10px;
}

.caixas article{
    background-color: var(--color5);
    color: white;
    padding: 30px;
    margin: 50px;
    border-radius: 50px;
    align-items: center;
    box-shadow: 0 0 20px 10px rgba(11, 63, 195, 0.51);
}



.atuacao .titulo {
    background-image: url(./assets/oceano2k.jpg);
    background-size: 100%;
    height: 170px;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.atuacao .titulo h2 {
    color: white;
    font-weight: 350;
}

.atuacao article img {
    max-width: 150px;
    height: auto;
    margin-right: 30px;
    border: 6px solid rgba(0, 0, 0, 0.791);
}

.atuacao article {
    display: flex;
    background-color: #007bffb9;
    box-shadow: 3px 3px 7px rgba(2, 2, 2, 0.5);
    border-radius: 20px;
    padding: 15px;
    margin: 30px;
    align-items: center;
}

.atuacao .texto {
    display: flex;
    flex-direction: column;
    color: black;
}

.atuacao article a {
    text-decoration: none;
    color: inherit;
    display: flex;
}

.atuacao article a:hover {
    transform: scale(1.1);
    transition: all 0.3s ease;
}
