@charset "UTF-8";
@import '../../colors.css';

button {
    background-color: var(--color5);
    color: white;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    font-size: 1em;
    border: 1px solid var(--color5);
}
button:hover {
    transition: 0.5s;
    transform: scale(1.1);
    background-color: white;
    color: var(--color5);
    border: 1px solid var(--color5);
}