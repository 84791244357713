@charset "UTF-8";
@import '../../../../../colors.css';

div#casa-branca > h1 {
    text-align: center;
    padding: 30px;
    background-color: var(--color5);
    color: white;

}

div#casa-branca > h2 {
    text-align: center;
    padding: 15px;
    color: black;
    background-color: var(--color3);
}

div#casa-branca > h3 {
    text-align: center;
    padding: 15px;
    color: black;
    background-color: var(--color3);
}

div#casa-branca > h4 {
    padding: 15px;
}

div#casa-branca > p {
    padding: 30px;

}

div#casa-branca li {
    display: block;
}

div#casa-branca a {
    width: 60%;
    margin: 0 auto;
    padding: 2px;
}

div#casa-branca a:hover {
    border-bottom: none;
}


