@charset "UTF-8";
@import '../../colors.css';

@media screen and (min-width: 768px) {
    section#contact {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px auto;
        width: 90%;
    }


    div#form article,
    div#infos {
        width: 30%;
    }

    section#maps {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}


@media screen and (min-width: 992px) {
    section#contact {
        width: 50%;
    }

    div#infos {
        width: 35%;
    }

    div#form {
        margin: 0 auto;
    }
}