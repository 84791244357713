@charset "UTF-8";
@import '../../colors.css';

section#contact {
    margin-top: 50px;
}

div#form article {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    margin: 15px auto;
}

div#infos {
    display: block;
    margin: 0 auto;
    text-align: center;
}

div#infos article {
    margin: 20px 0;
}

div#infos article h4 {
    color: var(--color3);
}

div#infos article img {
    color: white;
    background-color: var(--color5);
    border-radius: 50%;
    padding: 10px;
    width: 70px;
    margin: 5px;
}

section#maps h4 {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.165);
    width: 60%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 10px;
}

section#maps iframe {
    width: 100%;
    height: 300px;
    border: none;
}



